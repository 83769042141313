import React, {ChangeEvent} from "react";

type CallSetupState = {
    vmix: string
    name: string
    clicker: string
}

export default class CallSetup extends React.Component<any, CallSetupState> {
    constructor() {
        super(null);
        this.state = {
            vmix: '',
            name: '',
            clicker: '',
        };
    }

    handleVMixChange = (evt: ChangeEvent<HTMLInputElement>) => {
        this.setState({vmix: evt.target.value})
    }
    handleNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        this.setState({name: evt.target.value})
    }
    handleClickerChange = (evt: ChangeEvent<HTMLInputElement>) => {
        this.setState({clicker: evt.target.value})
    }

    render() {
        let origin = global.window.location.origin;
        let viewHelpUrl = `${origin}/{vMix ID}/{name}`
        let controlHelpUrl = `${viewHelpUrl}/{internet clicker code}`
        let viewUrl = "Viewing URLs require a VMix code and a name";
        let controlUrl = "Control URLs require a VMix code, name, and internet clicker code";
        if (this.state.vmix.length > 0 && this.state.name.length > 0) {
            viewUrl = `${origin}/${this.state.vmix}/${this.state.name}`

            if (this.state.clicker.length > 0) {
                controlUrl = `${viewUrl}/${this.state.clicker}`
            }
        }
        return (
            <div id="setup">
                <p>
                    The form below is to help construct URLs, however this may be done automatically by using the
                    following patterns:
                </p>
                <dl>
                    <dt>View</dt>
                    <dd>{viewHelpUrl}</dd>

                    <dt>Control</dt>
                    <dd>{controlHelpUrl}</dd>
                </dl>
                <form>
                    <ul>
                        <li>
                            <label htmlFor="vmix">VMix Call ID</label>
                            <input name="vmix" type="text" required value={this.state.vmix}
                                   onChange={this.handleVMixChange}/>
                        </li>
                        <li>
                            <label htmlFor="name">Display Name</label>
                            <input name="name" type="text" required value={this.state.name}
                                   onChange={this.handleNameChange}/>
                        </li>
                        <li>
                            <label htmlFor="clicker">Internet Clicker ID</label>
                            <input name="clicker" type="text" value={this.state.clicker}
                                   onChange={this.handleClickerChange}/>
                        </li>
                        <br/>
                        <li>
                            <label htmlFor="view-url">View Link</label>
                            <input name="view-url" value={viewUrl} disabled/>
                        </li>
                        <li>
                            <label htmlFor="view-url">Control Link</label>
                            <input name="view-url" value={controlUrl} disabled/>
                        </li>
                    </ul>
                </form>
            </div>
        )
    }
}