import React from 'react';

export type InternetClickerProps = {
    code: string;
    name: string;
}

export default class InternetClicker extends React.Component<InternetClickerProps> {
    render() {
        return (
            <div id="internet-clicker">
                <iframe src={`https://internetclicker.com/?code=${this.props.code}&branding=false&name=${this.props.name}`}
                        frameBorder="0" title="AOTV Internet clicker"/>
            </div>
        )
    }
}
