import React from 'react';
import aotv from './img/aotv.png';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    RouteComponentProps,
} from 'react-router-dom';
// @ts-ignore
import Split from 'react-split';
import './App.scss';
import VMixViewer from "./VMixViewer";
import InternetClicker from "./InternetClicker";
import CallSetup from "./CallSetup";

function App() {
    return (
        <Router>
            <div>
                <div id="header">
                    <a href="https://www.aotv.co.uk/">
                        <img src={aotv} alt="AOTV Logo"/>
                    </a>
                </div>
                <div id="main">
                    <Switch>
                        <Route exact path="/:vmix/:name" component={View}>
                        </Route>
                        <Route exact path="/:vmix/:name/:clicker" component={Control}>
                        </Route>
                        <Route path="/setup">
                            <CallSetup/>
                        </Route>
                        <Route path="/" component={Home}>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default App;

function Home() {
    global.window.location.href = "https://www.aotv.co.uk/";
    return null;
}

function View({match}: RouteComponentProps<{ vmix: string, name: string }>) {
    return (
        <div id="view" className="frames">
            <VMixViewer code={match.params.vmix} name={match.params.name}/>
        </div>
    )
}

function Control({match}: RouteComponentProps<{ vmix: string, name: string, clicker: string }>) {
    return (
        // <div id="control" className="frames">
            <Split sizes={[80, 20]} className="frames"
                   minSize={50}
                   expandToMin={false}
                   gutterSize={10}
                   gutterAlign="center"
                   snapOffset={30}
                   dragInterval={1}
                   direction="horizontal"
                   cursor="col-resize"
            >
                <VMixViewer code={match.params.vmix} name={match.params.name}/>
                <InternetClicker code={match.params.clicker} name={match.params.name}/>
            </Split>
        // </div>
    )
}
