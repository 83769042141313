import React from 'react';

export type VMixProps = {
    code: string;
    name: string;
}

export default class VMixViewer extends React.Component<VMixProps> {
    render() {
        return (
            <div id="vmix-viewer">
                <iframe src={`https://beta.vmixcall.com/call.htm?Key=${this.props.code}&Name=${this.props.name}`}
                        frameBorder="0" allow="camera;microphone;fullscreen" allowFullScreen
                        title="AOTV VMix"/>
            </div>
        )
    }
}